import React from "react";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'

export function RecordedVideo() {
  return (
    <>
      <div id="demo-video" className="px-6 pt-10 sm:pt-20 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <NavLink to="/demo" className="inline-flex items-center">
            <p className="text-base/7 font-semibold text-primary-600 hover:text-primary-700">Разгледай демото</p>
            <ChevronRightIcon className="ml-0.5 h-5 w-5 text-neutral-700" />
          </NavLink>
          <h2 className="mt-2 text-4xl font-semibold tracking-tight text-neutral-800 sm:text-6xl">Начини за помощ</h2>
          <p className="mt-8 text-pretty text-lg font-base text-neutral-700 sm:text-xl/8">
            Тестовете включват три метода за затвърждаване на материала - <i className="font-semibold text-primary-600">жокери</i>, <i className="font-semibold text-primary-600">спомагателни въпроси</i> и <i className="font-semibold text-primary-600">обяснения</i> след теста.
          </p>
        </div>
        <div className="relative my-7">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-3/5 md:w-2/5 lg:w-1/6 m-auto border-t border-neutral-200" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-neutral-200">
              <ArrowDownCircleIcon className="h-5 w-5 text-neutral-300" />
            </span>
          </div>
        </div>
      </div>

      <div className="w-[92%] sm:w-[75%] lg:w-[50%] mx-auto pb-10 lg:pb-24 cursor-pointer hover:opacity-60 transition duration-300 ease-in-out">
        <NavLink to="/demo" className="inline-flex items-center">
          <video loop autoPlay playsInline muted loop className="shadow-lifted shadow-primary-500/50 rounded-xl">
            <source src="atest8.mp4" type="video/mp4" />
          </video>
        </NavLink>
      </div>
    </>
  )
}
