import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import BackButton from "components/commons/BackButton";
import BadgedList from "components/commons/BadgedList";
import { SUBJECTS_PATH } from "components/routeConstants";
import testsApi from "apis/tests";

import { PageLoader } from "neetoui";
import EmptyState from "components/commons/EmptyState";
import EmptyListImage from "images/EmptyNotesList";

const MyTests = ({
  forDashboard = false,
  setDashboardTests
}) => {
  const [loading, setLoading] = useState(true);
  const [tests, setTests] = useState({});

  let routeHistory = useHistory();

  function goToSubjects() {
    routeHistory.push(SUBJECTS_PATH);
  }

  useEffect(() => {
    fetchMyTestTakings();
  }, []);

  const fetchMyTestTakings = async () => {
    try {
      setLoading(true);
      const {
        data: { tests },
      } = await testsApi.fetchMyTests();

      setTests(tests);
      if (forDashboard) {
        setDashboardTests(tests)
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="animate-fade py-10">
             <PageLoader text="Един момент..."/>
           </div>;
  }

  return (
    <>
      {tests.length ? (
        <>
          <BadgedList
            tests={tests}
            badgesFor='subject'
          />
          {!forDashboard && (
            <div className="flex items-center justify-center">
              <BackButton/>
            </div>
          )}
        </>
      ) : (
        <>
          <EmptyState
            image={EmptyListImage}
            subtitle="Върнете се на тази страница когато попълните първия тест."
            title="Все още нямате завършени тестове"
            primaryAction={() => goToSubjects()}
            primaryActionLabel="Към тестовете"
          />
        </>
      )}
    </>
  );
};

export default MyTests;
