import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser';

import Mathable from "components/commons/Mathable";
import GoPremium from "components/commons/GoPremium";
import testsApi from "apis/tests";
import ResultPercentage from "components/commons/ResultPercentage";
import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'

export default function WordClick({
  test,
  renderingForDemoPage = false
}) {
  function submitAnswersRequest() {
    if (renderingForDemoPage) {
      return testsApi.submitDemoAnswers;
    } else {
      return testsApi.submitAnswers;
    }
  }

  const scrollToTop = () => {
    setTimeout(() => { // without timeout it doesn't work on Firefox
      if (renderingForDemoPage) {
        window.scroll({top: 636, behavior: 'smooth'}) // equals the height of Demo HeroGrid component
      } else {
        window.scroll({top: 0, behavior: 'smooth'})
      }
    }, '10');
  }

  const submitAnswers = async (resultsToSubmit) => {
    try {
      submitAnswersRequest()(test.id, {results: resultsToSubmit})
    } catch (error) {
      logger.error(error)
    } finally {
      scrollToTop();
    }
  }

  const retake = () => {
    setResults({})
    setAvailableTiles(shuffledTiles())
    setRevealedTiles([])
    setRedFlickerTiles([])
    setHighlightMode(false)
  }

  function shuffledTiles() {
    return [...test.tiles].sort(() => 0.5 - Math.random())
  }

  function prevTile() {
    if (!isFinished()) {
      let newIndex = (availableTiles.indexOf(currentTile) + availableTiles.length - 1) % availableTiles.length
      setCurrentTile(availableTiles[newIndex])
    } else {
      let newIndex = (renderedTiles.indexOf(currentTile) + renderedTiles.length - 1) % renderedTiles.length
      setCurrentTile(renderedTiles[newIndex])
      setHighlightMode(true)
    }
  }

  function nextTile() {
    if (!isFinished()) {
      let newIndex = (availableTiles.indexOf(currentTile) + 1) % availableTiles.length
      setCurrentTile(availableTiles[newIndex])
    } else {
      let newIndex = (renderedTiles.indexOf(currentTile) + 1) % renderedTiles.length
      setCurrentTile(renderedTiles[newIndex])
      setHighlightMode(true)
    }
  }

  function isCorrect(tile) {
    return results[tile.id] == tile.id
  }

  function correctAnswers() {
    return Object.entries(results).filter(([key, value]) => key === value)
  }

  function isFinished() {
    return availableTiles.length === 0
  }

  function Navigation() {
    return (
      <span className="isolate inline-flex rounded-md p-4">
        <button
          type="button"
          onClick={prevTile}
          className="relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-neutral-700 ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 focus:z-10"
        >
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="min-w-[200px] sm:min-w-[300px] relative -ml-px inline-flex items-center justify-center bg-white px-2.5 py-2.5 ring-1 ring-inset ring-neutral-300 focus:z-10">
          <Mathable content={<span className="w-full text-neutral-600 text-base font-medium text-center">{currentTile.prompt}</span>}/>
        </div>
        <button
          type="button"
          onClick={nextTile}
          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-neutral-700 ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 focus:z-10"
        >
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </span>
    )
  }

  const isGreen = (tile) => {
    return revealedTiles.includes(tile);
  }

  const isRed = (tile) => {
    if (isFinished()) {
      return !isCorrect(tile);
    } else {
      return false;
    }
  }

  const redFlicker = (tile) => {
    return redFlickerTiles.includes(tile);
  }

  const focusedAfterFinish = (tile) => {
    return isFinished() && highlightMode && currentTile.id === tile.id
  }

  const tileClicked = (tile) => {
    if (test.visible) {
      if (!revealedTiles.includes(tile) && !isFinished()) {
        let correctTile = test.tiles.find((t) => t.id === currentTile.id);

        setAvailableTiles(availableTiles.filter(t => t.id !== correctTile.id));
        if (correctTile.id === tile.id) {
          setRevealedTiles([...revealedTiles, tile]);
        } else {
          setRedFlickerTiles([...redFlickerTiles, tile]);
          setTimeout(() => {
            setRedFlickerTiles(redFlickerTiles.filter(t => t.id !== tile.id));
          }, 400);
        }

        let answer = {}
        answer[currentTile.id] = tile.id
        setResults({ ...results, ...answer });
        nextTile()

        if (availableTiles.length === 1) {
          submitAnswers({ ...results, ...answer })
        }
      }

      if (isFinished()) {
        setCurrentTile(tile);
        setHighlightMode(true);
      }
    } else {
      window.scroll({top: 0, behavior: 'smooth'})
    }
  }

  function initialAvailableTiles() {
    if (test.last_test_taking) {
      return []
    }

    return shuffledTiles()
  }

  function initialRevealedTiles() {
    if (test.last_test_taking) {
      return test.tiles
    }

    return []
  }

  function renderGoPremium() {
    return (
      <GoPremium test={test} title="Нужен е абонамент за да достъпите теста" subtitle="За да направите този тест, необходимо е да се запишете за един от нашите абонаменти. Освен достъп, абонаментът включва и отстъпка при всяка рецензия."/>
    )
  }

  const [currentTile, setCurrentTile] = useState(shuffledTiles()[0])
  const [availableTiles, setAvailableTiles] = useState(initialAvailableTiles())
  const [renderedTiles, setRenderedTiles] = useState(shuffledTiles())
  const [revealedTiles, setRevealedTiles] = useState(initialRevealedTiles())
  const [redFlickerTiles, setRedFlickerTiles] = useState([])
  const [highlightMode, setHighlightMode] = useState(false)
  const [results, setResults] = useState(test.last_test_taking?.results || {})

  return (
    <>
      {!test.visible && (
        <div className="flex items-center justify-center">
          {renderGoPremium()}
        </div>
      )}

      <h2 className="text-center mt-5 lg:mt-3">
        {test.title}
      </h2>

      {test.intro && (
        <h4 className="md:max-w-3xl m-auto pt-4 pb-4 sm:pb-14 text-center text-l font-normal text-neutral-600 leading-6">
          {ReactHtmlParser(test.intro)}
        </h4>
      )}
      {test.image_url && (
        <img src={test.image_url} className="m-auto p-6"/>
      )}

      <div className="flex flex-col items-center justify-center md:space-y-6 md:space-y-12">
        {isFinished() && (
          <ResultPercentage correct={correctAnswers().length} total={test.tiles.length} />
        )}
        <Navigation/>

        <div className={`w-full ${test.visible ? '' : 'pt-1 fadingAwayRapid'}`}>
          <ul role="list" className="w-full m-auto text-center xl:w-[70%]">
            {renderedTiles.map((tile) => (
              <span className={
                `inline-flex items-center rounded-md m-1 md:m-2 px-2 py-1 md:px-4 md:py-2 text-base font-medium transition-colors duration-200 ease-in-out text-center shadow-subtler
                ${isGreen(tile) && !redFlicker(tile) ? 'bg-green-600 hover:bg-green-700 cursor-default'
                                                     : 'bg-neutral-100 text-neutral-600 hover:bg-neutral-200'}
                ${redFlicker(tile) || isRed(tile) ? 'bg-rose-600/75 hover:bg-rose-700/75' : ''}
                ${focusedAfterFinish(tile) ? 'bg-yellow-200 hover:bg-yellow-200 text-neutral-600' : ''}
                ${focusedAfterFinish(tile) || (!isGreen(tile) && !redFlicker(tile) && !isRed(tile)) ? 'text-neutral-600' : 'text-white'}
                ${test.visible ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                onClick={() => tileClicked(tile)}
                key={tile.id}
              >
                <Mathable content={tile.title}/>
              </span>
            ))}
          </ul>
        </div>

        {
          isFinished() && (
            <button onClick={retake} type="button" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-2.5 m-5 dark:bg-primary-600 dark:hover:bg-primary-600 focus:outline-none dark:focus:ring-primary-700">Повтори теста отново</button>
          )
        }
        {!test.visible && (
          <div className="sm:pb-3">
            <EllipsisHorizontalIcon className="h-6 w-6 m-auto text-neutral-400/50" />
            {renderGoPremium()}
          </div>
        )}
      </div>
    </>
  )
}
