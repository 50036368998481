import React, { useState, useEffect } from "react";
import Question from "./Question";
import DialogModal from "components/commons/DialogModal";
import TableInCard from "components/commons/TableInCard";
import MyComboBox from "components/commons/MyComboBox";
import FilePreview from "components/commons/FilePreview";
import testsApi from "apis/tests";

import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { XCircleIcon } from '@heroicons/react/20/solid'

function noOp() {}

const SelfAssessmentWizard = ({
  questions,
  testTaking,
  onProceed
}) => {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('selfAssessmentTitle').scrollIntoView();
    }, '200');
  }, []);

  function initialRemainingSubquestions() {
    if (displayedQuestion?.kind === "open_with_long_answer_subquestions") {
      const subquestionIds = Object.keys(Object.fromEntries(Object.entries(testTaking.results[displayedQuestion.id]).filter((s) => s[1]['marked_as_correct'] === 'pending')))
      return displayedQuestion.subquestions.filter(s => subquestionIds.includes(s.id));
    }

    return [];
  }

  function initialDisplayedSubquestion() {
    if (displayedQuestion?.kind === "open_with_long_answer_subquestions") {
      return remainingSubquestions[0];
    }

    return null;
  }

  const [remainingQuestions, setRemainingQuestions] = useState(questions);
  const [displayedQuestion, setDisplayedQuestion] = useState(questions[0]);

  const [remainingSubquestions, setRemainingSubquestions] = useState(initialRemainingSubquestions());
  const [displayedSubquestion, setDisplayedSubquestion] = useState(initialDisplayedSubquestion());

  const [pointAssessment, setPointAssessment] = useState(null);

  const selfAssess = async (assessedQuestion, assessedSubquestion, isCorrect, points = null) => {
    try {
      if (assessedSubquestion == null) {
        testsApi.selfAssessQuestion(testTaking.id, assessedQuestion.id, isCorrect, points).then((response) => {
          const nextQuestions = remainingQuestions.filter(q => q.id !== assessedQuestion.id);
          const nextQuestion = nextQuestions[0];

          if (nextQuestion?.kind === "open_with_long_answer_subquestions") {
            const nextQuestionRemainingSubquestionIds = Object.keys(Object.fromEntries(Object.entries(testTaking.results[nextQuestion.id]).filter((s) => s[1]['marked_as_correct'] === 'pending')))
            const nextRemainingSubquestions = nextQuestion.subquestions.filter(s => nextQuestionRemainingSubquestionIds.includes(s.id));

            setRemainingSubquestions(nextRemainingSubquestions);
            setDisplayedSubquestion(nextRemainingSubquestions[0]);
          }

          setRemainingQuestions(nextQuestions);
          setDisplayedQuestion(nextQuestion);
          setPointAssessment(null);
          onProceed(response.data.test_taking)
        })
      } else {
        testsApi.selfAssessSubquestion(testTaking.id, assessedQuestion.id, assessedSubquestion.id, isCorrect, points).then((response) => {
          const nextRemainingSubquestions = remainingSubquestions.filter(s => s.id !== assessedSubquestion.id);

          if (nextRemainingSubquestions.length > 0) {
            setRemainingSubquestions(nextRemainingSubquestions);
            setDisplayedSubquestion(nextRemainingSubquestions[0]);
          } else {
            const nextQuestions = remainingQuestions.filter(q => q.id !== assessedQuestion.id);
            const nextQuestion = nextQuestions[0];

            setRemainingQuestions(nextQuestions);
            setDisplayedQuestion(nextQuestion);

            if (nextQuestion?.kind === "open_with_long_answer_subquestions") {
              setRemainingSubquestions(nextQuestion.subquestions);
              setDisplayedSubquestion(nextQuestion.subquestions[0]);
            } else {
              setRemainingSubquestions([]);
              setDisplayedSubquestion(null);
            }
          }

          setPointAssessment(null);
          onProceed(response.data.test_taking)
        })
      }
    } catch (error) {
      logger.error(error)
    } finally {
      // no op
    }
  };

  function tableContent() {
    if (displayedQuestion.kind === "open_with_long_answer_subquestions") {
      const file = testTaking.results[displayedQuestion.id]?.[displayedSubquestion.id]?.['file_answer']
      const answerOrFile = (
        <>
          <p> {testTaking.results[displayedQuestion.id]?.[displayedSubquestion.id]?.['answer']} </p>
          {file && (
            <FilePreview fileName={file[1]} fileUrl={file[2]}/>
          )}
        </>
      )

      return [
        {key: 'Подточка', value: displayedQuestion.subquestions.find(s => s.id === displayedSubquestion.id).title, withFormulas: true},
        {key: 'Вашият отговор', value: answerOrFile, withFormulas: false},
        {key: 'Верен отговор', value: testTaking.correct_results[displayedQuestion.id][displayedSubquestion.id], withFormulas: true}
      ];
    }

    const file = testTaking.results[displayedQuestion.id]['file_answer']
    const answerOrFile = (
      <>
        <p> {testTaking.results[displayedQuestion.id]['answer']} </p>
        {file && (
          <FilePreview fileName={file[1]} fileUrl={file[2]}/>
        )}
      </>
    )

    return [
      {key: 'Вашият отговор', value: answerOrFile, withFormulas: false},
      {key: 'Верен отговор', value: testTaking.correct_results[displayedQuestion.id], withFormulas: true}
    ];
  }

  function maximumPoints() {
    return displayedSubquestion ? displayedSubquestion.display_points : displayedQuestion.display_points;
  }

  function renderPointSelection() {
    const maximum = maximumPoints();
    const selectValues = [0].concat(Array.from({length: maximum}, (_, i) => i + 1)).map(function(i) {
      return {id: i, name: i}
    });

    return (
      <div className="text-center">
        <div className="inline-flex gap-2 mb-4">
          <label htmlFor="index-number" className="block my-auto text-sm font-medium leading-6 text-neutral-900">
            Оценявам се на брой точки
          </label>
          <div className={`${maximumPoints() >= 10 ? 'w-28' : 'w-20'}`}>
            <MyComboBox
              key={displayedSubquestion?.id || displayedQuestion.id}
              initialSelected={maximumPoints()}
              values={selectValues}
              onSelection={setPointAssessment}
            />
          </div>
        </div>
        <div>
          <button
            onClick={() => selfAssess(displayedQuestion, displayedSubquestion, pointAssessment === maximumPoints(), pointAssessment)} type="button"
            className="inline-flex items-center gap-x-2 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 m-5 outline-none" >
            <ChevronRightIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Продължи
          </button>
        </div>
      </div>
    )
  }

  function renderDialog() {
    return (
      <>
        <DialogModal onProceed={onProceed} dialogClasses='bg-neutral-100 sm:max-w-[90%] lg:max-w-[70%] xl:max-w-[60%]' content={
          <>
            <div className="border-b border-neutral-200 bg-white px-4 py-5 sm:px-6 rounded-md m-3">
              <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                  <h3 id="selfAssessmentTitle" className="text-base font-semibold leading-6 text-neutral-900">{`Оценете въпрос №${displayedQuestion.index}`}</h3>
                  <p className="mt-1 text-sm text-neutral-500">
                    Помогнете ни да определим дали отговорът ви на следния въпрос е верен.
                  </p>
                </div>
              </div>
            </div>

            <Question
              question={displayedQuestion}
              selectedAnswer={testTaking.results[displayedQuestion.id]?.answer || ''}
              resultCorrectAnswer={testTaking.correct_results[displayedQuestion.id]}
              recordAnswer={noOp}
              unrecordAnswer={noOp}
              renderingForSelfAssessment={true}
              realMath={true}
              key={displayedQuestion.id}
            />

            <TableInCard
              title='Оценете вашия отговор'
              subtitle='Сравнете вашия отговор спрямо примерен верен отговор и оценете дали вашият е правилен.'
              additionalClasses="m-3"
              keyValueContent={tableContent()}
              contentBelow={
                <>
                  {maximumPoints() <= 1 && (
                    <div className="text-center">
                      <button onClick={() => selfAssess(displayedQuestion, displayedSubquestion, false)} type="button" className="inline-flex items-center gap-x-2 rounded-md bg-neutral-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-neutral-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-500 w-56 m-2 outline-none" >
                        <XCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Отговорът ми е грешен
                      </button>
                      <button onClick={() => selfAssess(displayedQuestion, displayedSubquestion, true)} type="button" className="inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 w-56 m-2 outline-none" >
                        <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Отговорът ми е верен
                      </button>
                    </div>
                  )}
                  {maximumPoints() > 1 && renderPointSelection()}
                </>
              }
            />
          </>
        }/>
      </>
    )
  }

  return (
    <>
      {remainingQuestions.length > 0 && (renderDialog())}
    </>
  );
};

export default SelfAssessmentWizard;
