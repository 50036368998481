import React from "react";

import ReactHtmlParser from 'react-html-parser';
import LogoSpin from "components/commons/LogoSpin";

function subscriptionMessage(user) {
  const base = `Абонаментът изтича на <strong>${user.subscription.expires_at}</strong>`
  const suffix = user.subscription.cancel_at_period_end ? 'няма да се поднови автоматично след това.' : 'ще се поднови автоматично след това.'

  return `${base} и ${suffix}`
}

export default function SuccessDisplay({ sessionId, user }) {
  return (
    <section className="py-10 sm:py-16 text-center">
      <div className="product Box-root">
        <LogoSpin classes="pb-10"/>

        <div className="description Box-root">
          <h2>Вие имате активен абонамент.</h2>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            {ReactHtmlParser(subscriptionMessage(user))}
          </p>
        </div>
      </div>
      <form action="/stripe/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <input type="hidden" name="user_id" value={user.id} />

        <button id="checkout-and-portal-button" type="submit" className="text-white bg-primary-700 hover:bg-primary-800 dark:bg-primary-500 font-medium rounded-lg text-sm px-5 py-2.5 m-10 text-center transition-colors duration-300 ease-in-out">
          Настройки на абонамента
        </button>
      </form>
    </section>
  );
};
