import React from "react";
import Image from 'next/image'
import ReactHtmlParser from 'react-html-parser';

import { Container } from './Container'
import backgroundImage from '../images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'Какво точно е платформата Еленор?',
      answer:
        '<u><a class="text-sky-700 font-medium" href="#demo-video">База от тестове по учебната програма</a></u> с жокери и допълнителни обяснения. Учениците могат да я използват за допълнителна подготовка или развлечение.',
    },
    {
      question: 'Как мога да използвам платформата?',
      answer: 'Направете <u><a class="text-sky-700 font-medium" href="./demo">краткия демо тест</a></u> за да изпробвате платформата. Регистрирайте се и разгледайте безплатните тестове, след което преценете дали абонаментът би ви бил полезен.',
    },
    {
      question: 'Платформата подходяща ли е за подготовка за НВО и ДЗИ?',
      answer:
        'Да, немалка част от тестовете са по изпитните формати на НВО и ДЗИ. Платформата включва и възможност за индивидуални рецензии от наши учители.',
    },
  ],
  [
    {
      question:
        'За деца на каква възраст е платформата?',
      answer:
        'Еленор e подходящ за ученици от 4-ти до 12-ти клас.',
    },
    {
      question: 'Освен тестове, предлагате ли уроци?',
      answer:
        'Не. Често въпросите включват помощни жокери и обяснения, които цитират релевантна част от уроците. Това помага за попълване на липсващи знания на момента.<br><br>Платформата се стреми да покрие учебния материал с тестове и да надгради над вече преподаваните уроци в училище, но не ги преподава отново.',
    },
    {
      question:
        'Какво означава "развлекателни" тестове?',
      answer:
        'Включваме две категории тестове - Задължителни и Развлекателни.<br><br>Задължителните тестове са директно по учебните уроци, наподобяват изпит или контролна работа. Развлекателните тестове са по-общи и целят да забавляват, често имат особен формат и наподобяват игра.',
    },
  ],
  [
    {
      question: 'Какви са цените?',
      answer:
        'Абонаментът предлага пълен достъп до платформата, като ценовите планове се различават единствено по тяхната дължина. Може да разгледате подробно <u><a class="text-sky-700 font-medium" href="#pricing">тук</a></u>.<br><br>Имате възможност и за заявка на индивидуална рецензия, цената на която варира спрямо обема на теста.',
    },
    {
      question: 'Мога ли да ползвам платформата безплатно?',
      answer: 'Да. Част от тестовете са безплатни и не изискват активен абонамент.',
    },
    {
      question: 'Кои предмети включва платформата?',
      answer:
        'Математика и Български език и литература. При интерес имаме готовност да работим за добавяне и на Английски език.',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-neutral-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-neutral-900 sm:text-4xl"
          >
            Въпроси <span className="text-primary-700">и отговори</span>
          </h2>
          <p className="mt-4 text-lg tracking-tight text-neutral-700">
            Разгледайте кратки отговори на основните въпроси, които получаваме за платформата до момента. За допълнителни въпроси може да се свържете директно с нас на имейл <i className="font-medium text-primary-700">info@elenor.bg</i>.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-neutral-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-neutral-700 text-pretty">{ReactHtmlParser(faq.answer)}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
