import React from "react";
import Link from 'next/link'

export function NavLinkNextJS({ href, children }) {
  return (
    <Link
      href={href}
      className="inline-block rounded-lg px-2 py-1 text-base text-neutral-700 hover:bg-primary-100 transition-colors duration-200 ease-in-out hover:text-neutral-900"
    >
      {children}
    </Link>
  )
}
