import React from "react";
import { NavLink } from "react-router-dom";
import { useUserState } from "contexts/user";
import { SUBSCRIPTION_PATH } from "components/routeConstants";
import { ChevronRightIcon } from '@heroicons/react/20/solid'

export default function GoPremium({
  title,
  subtitle,
  test
}) {
  const { user } = useUserState();

  return (
    <div className="bg-neutral-50 shadow sm:rounded-lg mt-4 mb-20 md:max-w-xl">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-neutral-900">{title}</h3>
        <div className="mt-2 max-w-l text-sm text-neutral-500">
          <p>
            {subtitle}
          </p>
        </div>
        <div className="mt-5">
        </div>

        <div className="flex flex-col sm:inline-flex sm:flex-row items-center gap-3.5">
          <NavLink
            to={SUBSCRIPTION_PATH}
            className="mx-auto flex rounded-lg"
          >
            <button type="button" className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500" >
              Към абонаментите
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </NavLink>
          <form action="/stripe/create-checkout-session" method="POST" className="my-auto">
            <input type="hidden" name="lookup_key" value={'SINGLE_TEST'} />
            <input type="hidden" name="user_id" value={user.id} />
            <input type="hidden" name="test_id" value={test.id} />

            <button type="submit" className="inline-flex items-center text-sm/6 font-semibold text-neutral-700" >
              Или разкрий само този тест
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
