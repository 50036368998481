import React, { useEffect } from "react";
import { Toastr } from "neetoui";

import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon, DocumentTextIcon, LockClosedIcon } from '@heroicons/react/24/outline'

export function Contact() {
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('contact_message_submitted')) {
      // remove contact_message_submitted from the url
      window.history.pushState({}, document.title, window.location.pathname);
      Toastr.success('Съобщението беше получено успешно.');
    }
  }, []);

  return (
    <div id="contact" className="relative isolate bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-neutral-200/75 ring-1 ring-neutral-900/10 lg:w-1/2">
              <svg
                aria-hidden="true"
                className="absolute inset-0 w-full h-full stroke-white [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              >
                <defs>
                  <pattern
                    x="100%"
                    y={-1}
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect fill="white" width="100%" height="100%" strokeWidth={0} />
                <svg x="100%" y={-1} className="overflow-visible fill-white">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
              </svg>
            </div>
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-neutral-900 sm:text-5xl">
              Свържете се <span className="text-primary-700">с нас</span>
            </h2>
            <p className="mt-6 text-lg/8 text-neutral-600">
              Може да ни потърсите на следния телефонен номер и имейл адрес или директно да изпратите съобщение през формата.
            </p>
            <dl className="mt-10 space-y-4 text-base/7 text-neutral-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <PhoneIcon aria-hidden="true" className="h-7 w-6 text-neutral-400" />
                </dt>
                <dd>
                  <a href="tel:+359 887 527 610" className="hover:text-neutral-900">
                    +359 887 527 610
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-neutral-400" />
                </dt>
                <dd>
                  <a href="mailto:info@elenor.bg" className="hover:text-neutral-900 italic">
                    info@elenor.bg
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <DocumentTextIcon aria-hidden="true" className="h-7 w-6 text-neutral-400" />
                </dt>
                <dd>
                  <a href="/terms.pdf" download className="hover:text-neutral-900">
                    Общи условия
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <LockClosedIcon aria-hidden="true" className="h-7 w-6 text-neutral-400" />
                </dt>
                <dd>
                  <a href="/gdpr.pdf" download className="hover:text-neutral-900">
                    Защита на лични данни
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form action="/api/v1/contact_messages" method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="first-name" className="block text-sm/6 font-semibold text-neutral-900">
                  Име
                </label>
                <div className="mt-2.5">
                  <input
                    id="first-name"
                    name="first_name"
                    type="text"
                    autoComplete="given-name"
                    className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-neutral-900 placeholder:text-neutral-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-primary-600 border-neutral-300"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm/6 font-semibold text-neutral-900">
                  Фамилия
                </label>
                <div className="mt-2.5">
                  <input
                    id="last-name"
                    name="last_name"
                    type="text"
                    autoComplete="family-name"
                    className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-neutral-900 placeholder:text-neutral-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-primary-600 border-neutral-300"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm/6 font-semibold text-neutral-900">
                  Имейл
                </label>
                <div className="mt-2.5">
                  <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-neutral-900 placeholder:text-neutral-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-primary-600 border-neutral-300"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone-number" className="block text-sm/6 font-semibold text-neutral-900">
                  Телефонен номер
                </label>
                <div className="mt-2.5">
                  <input
                    id="phone-number"
                    name="phone"
                    type="tel"
                    autoComplete="tel"
                    className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-neutral-900 placeholder:text-neutral-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-primary-600 border-neutral-300"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm/6 font-semibold text-neutral-900">
                  Съобщение
                </label>
                <div className="mt-2.5">
                  <textarea
                    required
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full rounded-md bg-white px-3.5 py-2 text-base text-neutral-900 placeholder:text-neutral-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-primary-600 border-neutral-300"
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-primary-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Изпрати съобщение
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
