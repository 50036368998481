import React from "react";
import ReactHtmlParser from 'react-html-parser';
import Image from 'next/legacy/image'

import { Container } from './Container'
import minimath from '../images/avatars/minimath.png'
import minibg from '../images/avatars/minibg.png'

import { LazyLoadImage } from "react-lazy-load-image-component";

const bullet = `
<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-4 h-4" style="display:inline-table;">
  <path d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" />
</svg>
`

const testimonials = [
  [
    {
      content:
`В кой ред думата е изписана правилно?
<br><br>
 ${bullet} <i>порозовяли</i><br>
 ${bullet} <i>осмирителен</i><br>
 ${bullet} <i>следдипломен</i><br>
 ${bullet} <i>незговорчиви</i>`,
      author: {
        name: 'Подготовка ДЗИ, 12. клас',
        role: 'Задължителни',
        image: minibg,
      },
    },
    {
      content:
`Природата като пейзаж на човешката душа присъства в:
<br><br>
 ${bullet} <i><i>"Колко си хубава!"</i><br>
 ${bullet} <i><i>"Градушка"</i><br>
 ${bullet} <i><i>"Спи езерото"</i><br>
 ${bullet} <i><i>"До моето първо либе"</i></i>`,
      author: {
        name: 'Български език, 11. клас',
        role: 'Задължителни',
        image: minibg,
      },
    },
  ],
  [
    {
      content:
        `Кои три различни числа дават един и същ резултат независимо дали ги събирате или умножавате?
<br><br>
 ${bullet} <i>едно, десет, сто</i><br>
 ${bullet} <i>едно, две, три</i><br>
 ${bullet} <i>нула, едно, две</i><br>
 ${bullet} <i>три, пет, осем</i>`,
      author: {
        name: 'Математика, 5. клас',
        role: 'Развлекателни',
        image: minimath,
      },
    },
    {
      content:
`Онзи ден бях на 25 години. Догодина ще стана на 28. Това е вярно само един ден в годината. Кога е рожденият ми ден?
<br><br>
 ${bullet} <i>31 декември</i><br>
 ${bullet} <i>1 януари</i><br>
 ${bullet} <i>6 септември</i><br>
 ${bullet} <i>3 март</i>`,
      author: {
        name: 'Математика, 10. клас',
        role: 'Развлекателни',
        image: minimath,
      },
    },
  ],
  [
    {
      content:
`Познайте българския писател.
<br><br>
Можете ли да познаете тези известни български писатели и поети по тяхна снимка?</i>`,
      author: {
        name: 'Български език, 12. клас',
        role: 'Развлекателни',
        image: minibg,
      },
    },
    {
      content:
`Един от ъглите, получени при пресичането на две прави, е 80\% от другия. Мярката на съседния му ъгъл е:
<br><br>
 ${bullet} <i>36°</i><br>
 ${bullet} <i>80°</i><br>
 ${bullet} <i>100°</i><br>
 ${bullet} <i>144°</i>`,
      author: {
        name: 'Математика, 7. клас',
        role: 'Задължителни',
        image: minimath,
      },
    },
  ],
]

function QuoteIcon(props) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

// id is changed to examples by me
export function Testimonials() {
  return (
    <section
      id="examples"
      aria-label="What our customers are saying"
      className="bg-neutral-50 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-neutral-900 sm:text-4xl">
            Примерни <span className="text-primary-700">въпроси</span>
          </h2>
          <p className="mt-4 text-lg tracking-tight text-neutral-700">
            Освен по класове, въпросите в платформата са разделени в две основни категории – Задължителни и Развлекателни.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-neutral-900/10">
                      <QuoteIcon className="absolute left-6 top-6 fill-neutral-100" />
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-neutral-900">
                          {ReactHtmlParser(testimonial.content)}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-neutral-100 pt-6">
                        <div>
                          <div className="font-display text-base text-neutral-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-neutral-500">
                            {testimonial.author.role}
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-neutral-50">
                          <LazyLoadImage
                            className="h-14 w-14 object-cover"
                            src={testimonial.author.image}
                            alt=""
                            width={56}
                            height={56}
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
