import React from "react";

const stats = [
  { id: 1, name: 'За ученици от четвърти до дванадесети клас', value: '4 до 12 клас' },
  { id: 2, name: 'Математика и Български език и литература', value: '2 предмета' },
  { id: 3, name: 'С жокери, обяснения и спомагателни въпроси', value: 'Над 4000 въпроса' },
  { id: 4, name: 'Задължителна и Развлекателна, и двете по учебния материал', value: '2 категории' },
]

export default function Numbers() {
  return (
    <div className="py-14 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h4 className="text-lg font-medium tracking-tight text-neutral-900 sm:text-xl">
              Съчетание на полезното с приятното
            </h4>
            {/*<p className="font-display text-base text-neutral-900">
              Платформата накратко в числа
            </p>*/}
            <p className="mt-1 text-base leading-8 text-neutral-600 italic font-normal">
              Платформата накратко в числа
            </p>
          </div>
          <dl className="mt-10 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col bg-white/75 p-8">
                <dt className="text-sm font-normal leading-6 text-neutral-600">{stat.name}</dt>
                <dd className="order-first text-2xl font-semibold tracking-tight text-neutral-700">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
