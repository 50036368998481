import React from 'react'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import MyRealMath from "./MyRealMath";

export default function TableInCard({
  title,
  subtitle,
  keyValueContent, // ex. [{key: 'abc', value: 'text'}, {key: 'def', value: 'texty'}]
  contentBelow = null,
  additionalClasses = ''
}) {
  return (
    <div className={`bg-white shadow sm:rounded-lg ${additionalClasses}`}>
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-neutral-900">{title}</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-neutral-500">{subtitle}</p>
      </div>
      <div className="border-t border-neutral-100">
        <dl className="divide-y divide-neutral-100">
          {keyValueContent.map((entry) => (
            <div key={entry.key} className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-neutral-900">{entry.key}</dt>
              <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-3 sm:mt-0 whitespace-pre-line">
                {entry.withFormulas ? (<MyRealMath text={entry.value}/>) : entry.value}
              </dd>
            </div>
          ))}
        </dl>

        <dl className="divide-y divide-neutral-100">
          <div className="px-4 py-6">
            {contentBelow}
          </div>
        </dl>
      </div>
    </div>
  )
}
