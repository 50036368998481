import React, { useEffect, useState } from "react";

import Tooltip from "components/commons/Tooltip";
import { HomeIcon, FlagIcon, CheckIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Breadcrumbs({
  questions,
  selectedQuestion,
  results,
  testTaking,
  onQuestionClick,
  onHomeClick,
  onFlagClick
}) {
  const correctResults = testTaking?.correct_results || []
  const guessedQuestions = testTaking?.guessed_questions || []
  const pendingSelfAssessmentQuestions = testTaking?.pending_self_assessment_questions || []

  function homeIcon () {
    return (
      <li key="home" className="flex justify-center w-7.5">
        <Tooltip
          text="Всички въпроси"
          classes="-mt-24"
          object={
            <button className="text-neutral-400 hover:text-neutral-500" onClick={onHomeClick}>
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            </button>
          }
        />
      </li>
    )
  }

  function isCorrect(question) {
    return guessedQuestions.includes(question.id)
  }

  function checkBadgeClasses(question) {
    if (!results.hasOwnProperty(question.id)) {
      return 'opacity-0'
    } else if (correctResults[question.id] == null) {
      return 'opacity-100 text-neutral-700 hover:text-neutral-950'
    } else if (pendingSelfAssessmentQuestions.includes(question.id)) {
      return 'opacity-100 text-neutral-700 hover:text-neutral-950' // same as above, for now
    } else {
      const color = isCorrect(question) ? 'bg-green-600/75' : 'bg-rose-400'
      return `${color} text-white`
    }
  }

  function tickCircle(question) {
    return (
      <span
        className={classNames(
          checkBadgeClasses(question),
          'animate-fadeQuick absolute font-medium ml-1.5 p-1 rounded-full shadow-subtle z-10'
        )}>
          <CheckIcon className="h-2.5 w-2.5 flex-shrink-0" aria-hidden="true" />
      </span>
    )
  }

  function numberAsFirstCell (question) {
    return (
     <li key={question.id} className="flex justify-center w-7.5">
      <div className="flex items-center text-center">
        <button
          className={classNames(
            selectedQuestion?.id === question?.id
              ? 'font-bold text-neutral-700'
              : 'font-normal',
            'relative h-5 w-5 text-sm hover:font-semibold text-neutral-500 hover:text-neutral-700'
          )}
          onClick={() => onQuestionClick(question)}
        >
          {question.index}
          {results.hasOwnProperty(question.id) && tickCircle(question)}
        </button>
      </div>
    </li>
    )
  }

  function numberCell (question) {
    return (
      <li key={question.id} className="flex w-16">
        <div className="flex items-center">
          <svg
            className="h-full w-6 flex-shrink-0 text-neutral-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <button
            className={classNames(
              selectedQuestion?.id === question?.id
                ? 'font-bold text-neutral-700'
                : 'font-normal',
              'relative pl-5 w-full text-sm hover:font-semibold text-neutral-500 hover:text-neutral-700 cursor-pointer'
            )}

            onClick={() => onQuestionClick(question)}
          >
            {question.index}
            {tickCircle(question)}
            <div className="opacity-0 absolute w-16 h-10 -inset-3"></div>
          </button>
        </div>
      </li>
    )
  }

  function flagIcon() {
    return (
      <Tooltip
        text="Неотговорени въпроси"
        classes="mt-2 ml-5 text-center"
        key="flag"
        object={
          <li className="flex w-16">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-neutral-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <button className="ml-4 w-5 text-neutral-400 hover:text-neutral-500" onClick={onFlagClick}>
                <FlagIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              </button>
            </div>
          </li>
        }
      />
    )
  }

  function flagFirstCell() {
    return (
      <Tooltip
        text="Неотговорени въпроси"
        classes="mt-2 text-center"
        object={
          <li key="flag" className="flex h-11 w-8">
            <div className="flex items-center">
              <button className="text-neutral-400 hover:text-neutral-500" onClick={onFlagClick}>
                <FlagIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              </button>
            </div>
          </li>
        }
      />
    )
  }

  function elementFor(questions) {
    if (questions[0] === 'flag') {
      return <>
        {flagFirstCell()}
      </>
    } else if (questions[0] === 'home') {
      return <>
        {homeIcon()}
        {questions.slice(1).map((question) => (
          question == 'flag' ? flagIcon() : numberCell(question))
        )}
      </>
    } else {
      return <>
        {numberAsFirstCell(questions[0])}
        {questions.slice(1).map((question) => (
          question == 'flag' ? flagIcon() : numberCell(question))
        )}
      </>
    }
  }

  return (
    <>
      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex space-x-3 rounded-md bg-white px-6 shadow mt-1 sm:mt-2">
          {elementFor(questions)}
        </ol>
      </nav>
    </>
  )
}
